import { Injectable } from '@angular/core';
import { DOMAIN_CNAE, DOMAIN_COMPANY } from '@app/data/constants/domains';
import { ServerErrorService } from '@core/services/server-error.service';
import { mapToCriteriaResponse } from '@core/utils/map-to-criteria-response';
import { ICompany } from '@models/ICompany';
import { ICriteriaResponse } from '@models/ICriteriaResponse';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { Database } from '@paella-front/ngx-database/lib/database';
import {
  HttpCriteria,
  SuperHttpClientProvider,
} from '@paella-front/ngx-super-http-client';
import { BehaviorSubject, Observable, tap } from 'rxjs';

export interface ICompanySearch {
  cif: string;
  fiscalName: string;
  id: number;
}

const COMPANY_SELECTED = 'company_selected';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  _company$: BehaviorSubject<ICompany | null> = new BehaviorSubject<ICompany | null>(
    null
  );
  _companies$ = new BehaviorSubject<Array<ICompany> | null>(null);
  _refreshCompanies$ = new BehaviorSubject<boolean>(false);
  private companySelected: ICompany = null;
  private readonly $cnaehttp: ReturnType<SuperHttpClientProvider['use']>;
  private readonly $ehttp: ReturnType<SuperHttpClientProvider['use']>;

  /////////////////
  // Constructor //
  /////////////////

  constructor(
    private $db: DatabaseProvider,
    $$shttp: SuperHttpClientProvider,
    $$cnaehttp: SuperHttpClientProvider,
    public $serverError: ServerErrorService
  ) {
    this.$ehttp = $$shttp.use(DOMAIN_COMPANY);
    this.$cnaehttp = $$cnaehttp.use(DOMAIN_CNAE);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  ///////////////////////
  // Public Accessors //
  ///////////////////////

  get company$(): Observable<ICompany | null> {
    return this._company$.asObservable();
  }

  get companies$(): Observable<Array<ICompany | null>> {
    return this._companies$.asObservable();
  }

  get refreshCompanies$(): Observable<boolean> {
    return this._refreshCompanies$.asObservable();
  }

  set company(data: ICompany | null) {
    this._company$.next(data);
    this.setCompanySelected(data);
  }

  set companies(data: Array<ICompany> | null) {
    this._companies$.next(data);
  }

  set refreshCompanies(value: boolean) {
    this._refreshCompanies$.next(value);
  }

  async setCompanySelected(value: ICompany): Promise<void> {
    this.companySelected = value;
    await (this.$db.default as Database).set(COMPANY_SELECTED, value);
  }

  getCompanySelected(): ICompany | null {
    return this.companySelected;
  }

  async getStoredCompanySelected(): Promise<ICompany> {
    return await (this.$db.default as Database).get(COMPANY_SELECTED);
  }

  getAll(): Observable<ICriteriaResponse<ICompany>> {
    return this.$ehttp
      .filter(
        `/company/search`,
        new HttpCriteria({
          _page: -1,
          _perPage: -1,
          _filters: [
            { field: 'isDeleted', operator: '=', value: false as any, condition: 'AND' },
          ],
          _sort: [{ field: 'name', type: 'ASC' }],
        }),
        { observe: 'response' }
      )
      .pipe(
        mapToCriteriaResponse<ICompany>(),
        tap((response: ICriteriaResponse<ICompany>) =>
          this._companies$.next(response.body)
        )
      );
  }

  list(criteria: HttpCriteria): Observable<ICriteriaResponse<ICompany>> {
    return this.$ehttp
      .filter(`/company/search`, criteria, { observe: 'response' })
      .pipe(mapToCriteriaResponse());
  }

  get(id: number): Observable<ICompany> {
    return this.$ehttp.get<ICompany>(`/company/${id}`);
  }

  getCnae(cifNum: number): Observable<ICompany> {
    return this.$cnaehttp.get<ICompany>(`/cif/${cifNum}`);
  }

  create(data: ICompany): Observable<ICompany> {
    return this.$ehttp.post<ICompany>(`/company`, data);
  }

  edit(id: number, data: ICompany): Observable<ICompany> {
    return this.$ehttp.put<ICompany>(`/company/${id}`, data);
  }

  delete(id: number, value: number = 1): Observable<boolean> {
    return this.$ehttp.delete<boolean>(`/company/${id}/${value}`);
  }

  addImage(companyId: number, file: File): Observable<ICompany> {
    const formData = new FormData();
    formData.append('file', file);
    return this.$ehttp.post<ICompany>(`/company/${companyId}/image`, formData);
  }

  deleteImage(companyId: number): Observable<ICompany> {
    return this.$ehttp.delete<ICompany>(`/company/${companyId}/image`);
  }

  // eslint-disable-next-line id-length
  getListCompaniesCif(companyId: number, cif: string): Observable<ICompanySearch[]> {
    return this.$ehttp.get<ICompanySearch[]>(`/company/${companyId}/list/${cif}`);
  }

  deletePhysically(id: number): Observable<ICompany> {
    return this.$ehttp.delete<ICompany>(`/company/${id}`);
  }

  setActive(id: number, isActive: boolean): Observable<ICompany> {
    return this.$ehttp.put<ICompany>(`/company/${id}`, { isActive });
  }
}
